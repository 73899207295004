import React from "react";
import Layout from "../../components/Layout";
import { Container } from "@chakra-ui/react";
import PageHeadingSection from "../../components/PageHeadingSection";

export default function CQI_IRCA() {
  return (
    <Layout>
      <PageHeadingSection title={"CQI-IRCA Calendar"} />
      <Container maxW={"7xl"} py={5}>
        <iframe
          // src={CQI_IRCA_Calendar}
          src="/training-calendars/CQI-IRCA_Training_Calendar.pdf"
          width="90%"
          height="900"
          title="CQI-IRCA Calendar"
          style={{ margin: "auto" }}
        ></iframe>
      </Container>
    </Layout>
  );
}
